<template>
  <div>
    <ConnectWallet />
    <div class="z-0">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://envato-shoebox-0.imgix.net/6829/fce7-ef24-41fa-935b-c6f8d40c85db/Journalists_Conference_Outdoors_NB_0491_af2.jpg?auto=compress%2Cformat&fit=max&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark2.png&markalign=center%2Cmiddle&markalpha=18&w=1600&s=946935118cd98296d0cae750959d14ce');
        "
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-7xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-300 to-gray-100"
          >
            INTERVIEWS
          </h2>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <a
          href="/search/News"
          class="text-xl font-bold xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-green-600 via-purple-600 to-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
        >
          INTERVIEWS
        </a>
        <hr class="h-px my-4 border-blue-700 border-2" />

        <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 md:grid-cols-3"></div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2">
          <div
            class="transition duration-300 ease-in-out hover:-translate-y-1 cursor-pointer rounded-sm rounded"
            style="margin-top: 5px"
            v-for="s in prompts"
            :key="s"
          >
            <div
              class="p-1 bg-gradient-to-r from-green-500 via-pink-600 to-blue-500 rounded"
            >
              <div class="border-none rounded bg-gray-900">
                <div
                  class="block max-w-sm rounded-lg bg-white bg-cover p-2 shadow-lg h-96 w-full"
                  :style="'background-image: url(' + s.img_1 + ')'"
                >
                  <div
                    class="flex flex-wrap content-end justify-center h-full w-full"
                  >
                    <div>
                      <h3 class="mb-2 text-lg text-neutral-600 text-white">
                        @Liza_O
                      </h3>
                    </div>
                  </div>
                </div>

                <h5
                  class="mb-2 p-5 text-xl text-white font-extrabold font-sans"
                >
                  Who is Virtual Cat Girl and Music Artist, Lechat?
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prompt from "@/api/prompt";
import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],

      ref: this.$route.params.ref,
    };
  },
  components: {
    ConnectWallet,
  },
  methods: {
    async getNewPrompts() {
      await Prompt.trendPrompts()
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getNewPrompts();
  },
};
</script>
